import { gql } from "@apollo/client";

export const GET_PRODUCT_QUERY = gql`
query ProductDetails($id: ID!, $channel: String, $firstValues: Int, $afterValues: String, $lastValues: Int, $beforeValues: String) {
  product(id: $id, channel: $channel) {
    ...Product
    category {
      ...CategoryWithAncestors
      __typename
    }
    __typename
  }
}

fragment Product on Product {
  ...ProductVariantAttributes
  ...Metadata
  name
  slug
  description
  seoTitle
  seoDescription
  rating
  defaultVariant {
    id
    __typename
  }
  category {
    id
    name
    __typename
  }
  collections {
    id
    name
    __typename
  }
  channelListings {
    ...ChannelListingProductWithoutPricing
    __typename
  }
  media {
    ...ProductMedia
    __typename
  }
  isAvailable
  variants {
    ...ProductDetailsVariant
    __typename
  }
  productType {
    id
    name
    hasVariants
    __typename
  }
  weight {
    ...Weight
    __typename
  }
  taxClass {
    id
    name
    __typename
  }
  __typename
}

fragment ProductVariantAttributes on Product {
  id
  attributes {
    attribute {
      id
      slug
      name
      inputType
      entityType
      valueRequired
      unit
      choices(
        first: $firstValues
        after: $afterValues
        last: $lastValues
        before: $beforeValues
      ) {
        ...AttributeValueList
        __typename
      }
      __typename
    }
    values {
      ...AttributeValueDetails
      __typename
    }
    __typename
  }
  productType {
    id
    variantAttributes {
      ...VariantAttribute
      __typename
    }
    __typename
  }
  channelListings {
    channel {
      id
      name
      currencyCode
      __typename
    }
    __typename
  }
  __typename
}

fragment AttributeValueList on AttributeValueCountableConnection {
  pageInfo {
    ...PageInfo
    __typename
  }
  edges {
    cursor
    node {
      ...AttributeValueDetails
      __typename
    }
    __typename
  }
  __typename
}

fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
  __typename
}

fragment AttributeValueDetails on AttributeValue {
  ...AttributeValue
  plainText
  richText
  __typename
}

fragment AttributeValue on AttributeValue {
  id
  name
  slug
  file {
    ...File
    __typename
  }
  reference
  boolean
  date
  dateTime
  value
  __typename
}

fragment File on File {
  url
  contentType
  __typename
}

fragment VariantAttribute on Attribute {
  id
  name
  slug
  inputType
  entityType
  valueRequired
  unit
  choices(
    first: $firstValues
    after: $afterValues
    last: $lastValues
    before: $beforeValues
  ) {
    ...AttributeValueList
    __typename
  }
  __typename
}

fragment Metadata on ObjectWithMetadata {
  metadata {
    ...MetadataItem
    __typename
  }
  privateMetadata {
    ...MetadataItem
    __typename
  }
  __typename
}

fragment MetadataItem on MetadataItem {
  key
  value
  __typename
}

fragment ChannelListingProductWithoutPricing on ProductChannelListing {
  id
  isPublished
  publishedAt
  isAvailableForPurchase
  availableForPurchaseAt
  visibleInListings
  channel {
    id
    name
    currencyCode
    __typename
  }
  __typename
}

fragment ProductMedia on ProductMedia {
  id
  alt
  sortOrder
  url(size: 1024)
  type
  oembedData
  __typename
}

fragment ProductDetailsVariant on ProductVariant {
  id
  sku
  name
  attributes {
    attribute {
      id
      name
      __typename
    }
    values {
      ...AttributeValueDetails
      __typename
    }
    __typename
  }
  media {
    url(size: 200)
    __typename
  }
  stocks {
    ...Stock
    __typename
  }
  trackInventory
  preorder {
    ...Preorder
    __typename
  }
  channelListings {
    ...ChannelListingProductVariant
    __typename
  }
  quantityLimitPerCustomer
  __typename
}

fragment Stock on Stock {
  id
  quantity
  quantityAllocated
  warehouse {
    ...Warehouse
    __typename
  }
  __typename
}

fragment Warehouse on Warehouse {
  id
  name
  __typename
}

fragment Preorder on PreorderData {
  globalThreshold
  globalSoldUnits
  endDate
  __typename
}

fragment ChannelListingProductVariant on ProductVariantChannelListing {
  id
  channel {
    id
    name
    currencyCode
    __typename
  }
  price {
    ...Money
    __typename
  }
  costPrice {
    ...Money
    __typename
  }
  preorderThreshold {
    quantity
    soldUnits
    __typename
  }
  __typename
}

fragment Money on Money {
  amount
  currency
  __typename
}

fragment Weight on Weight {
  unit
  value
  __typename
}

fragment CategoryWithAncestors on Category {
  id
  name
  parent {
    id
    name
    __typename
  }
  level
  ancestors(first: 1) {
    edges {
      node {
        id
        name
        __typename
      }
      __typename
    }
    __typename
  }
  __typename
}`;


export const PRODUCT_TYPE_QUERY=gql`query ProductType($id: ID!, $firstValues: Int, $afterValues: String, $lastValues: Int, $beforeValues: String) {
  productType(id: $id) {
    id
    name
    hasVariants
    productAttributes {
      id
      inputType
      entityType
      slug
      name
      valueRequired
      unit
      visibleInProductForm
      choices(
        first: $firstValues
        after: $afterValues
        last: $lastValues
        before: $beforeValues
      ) {
        ...AttributeValueList
        __typename
      }
      __typename
    }
    taxClass {
      id
      name
      __typename
    }
    __typename
  }
}

fragment AttributeValueList on AttributeValueCountableConnection {
  pageInfo {
    ...PageInfo
    __typename
  }
  edges {
    cursor
    node {
      ...AttributeValueDetails
      __typename
    }
    __typename
  }
  __typename
}

fragment PageInfo on PageInfo {
  endCursor
  hasNextPage
  hasPreviousPage
  startCursor
  __typename
}

fragment AttributeValueDetails on AttributeValue {
  ...AttributeValue
  plainText
  richText
  __typename
}

fragment AttributeValue on AttributeValue {
  id
  name
  slug
  file {
    ...File
    __typename
  }
  reference
  boolean
  date
  dateTime
  value
  __typename
}

fragment File on File {
  url
  contentType
  __typename
}`