import React, { useState } from "react";
import { Button, Box } from "@material-ui/core";
import AditionalAttributes from "@dashboard/products/components/AditionalAttributes";

const TopMenu: React.FC = props => {
  const { children, product } = props;
  const [activeComponent, setActiveComponent] = useState<string | null>("Product"); // State to track active component

  const showProductComponent = () => {
    setActiveComponent("Product");
  };

  const showAttributesComponent = () => {
    setActiveComponent("Attributes");
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "Product":
        return children;
      case "Attributes":
        return <AditionalAttributes attributes={product.attributes} productId={product.id} />;
      default:
        return <div>Welcome! Please select an option from the buttons below.</div>;
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="left" marginTop="10px" padding={"10px"} mb={3}>
        <Button color="primary" variant="outlined" onClick={showProductComponent}>
          Product
        </Button>
        <Button color="primary" variant="outlined" onClick={showAttributesComponent} style={{ marginLeft: 8 }}>
          Additional Attributes
        </Button>
      </Box>
      <Box p={3}>{renderComponent()}</Box>
    </div>
  );
};

export default TopMenu;
