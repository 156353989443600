import { gql } from "@apollo/client";

export const CREATE_NEW_APPOINTMENT = gql`
  mutation createNewDate($input: DateInput!) {
    agendaDate(input: $input) {
      agenda {
        id
        name
        agoraSessionToken
        allDay
        createdAt
      }
    }
  }
`;

export const UPDATE_DATE = gql`
   mutation createNewDate($input: DateInput!) {
    agendaDate(input: $input) {
      agenda {
        id
        name
        agoraSessionToken
        allDay
        createdAt
      }
    }
  }
`;


export const GET_DATES=gql`
query dates($id: ID!) {
  datesByProduct(productId:$id) {
    id
    name
    startDate
    endDate
    description
    isEnable
    studentQty
    createdAt
    agoraSessionToken
    allDay
    isVr
    isOnline
  }
}`