import React from "react";
import { Grid, Paper, Typography, TextField, Button, CircularProgress, Box } from "@material-ui/core";
import { useGenerateDescription } from "@dashboard/@wl/components/OpenAI/hooks/useGenerateDescription";

const GenerateDescriptionForm = () => {
  const { submit, description, handleInputChange, loading } = useGenerateDescription();

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h2">Generate description</Typography>
      <br />
      <form noValidate autoComplete="off" onSubmit={submit}>
        <Grid container spacing={3}>
          {/* Title Input */}
          <Grid item xs={12}>
            <TextField label="request" name="request" variant="outlined" fullWidth onChange={handleInputChange} />
          </Grid>

          {/* Description Input */}
          <Grid item xs={12}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}>
                <CircularProgress />
              </Box>
            ) : (
              <TextField
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                value={description}
              />
            )}
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default GenerateDescriptionForm;
