import React, { useState } from "react";
import Scheduler from "@dashboard/products/components/AditionalAttributes/components/scheduler";
import { Grid, Paper, Typography, TextField, Button, IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import GenerateDescription from "@dashboard/@wl/components/OpenAI/components/GenerateDescriptionForm";
interface Attribute {
  attribute: {
    visibleInProductForm: boolean;
    id: string;
    slug: string;
    name: string;
    inputType: string;
    valueRequired: boolean;
    choices: {
      pageInfo: {
        endCursor: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        startCursor: string | null;
      };
      edges: Array<{
        cursor: string;
        node: {
          id: string;
          name: string;
          slug: string;
          value: string;
        };
      }>;
    };
  };
  values: Array<{
    id: string;
    name: string;
    slug: string;
    value: string;
  }>;
}

interface Props {
  attributes: Attribute[];
  productId: string;
}

const AttributeDisplay: React.FC<Props> = ({ attributes, productId }) => {
  const [showForm, setShowForm] = useState(true);

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  return (
    <Grid container spacing={3}>
      {/* Left Column */}
      <Grid item xs={12} sm={showForm ? 8 : 12}>
        <Paper style={{ padding: 16, position: "relative" }}>
          {/* Toggle Button */}
          <IconButton
            style={{ position: "absolute", top: 16, right: 16 }}
            onClick={toggleFormVisibility}
          >
            {showForm ? <VisibilityOff /> : <Visibility />}
          </IconButton>

          {attributes
            .filter(attr => !attr.attribute.visibleInProductForm)
            .map((attr, index) => {
              if (attr.attribute.inputType === "CALENDAR") {
                return (
                  <div key={index}>
                    <Scheduler name={attr.attribute.inputType} productId={productId} />
                  </div>
                );
              }
              return null;
            })}
        </Paper>
      </Grid>

      {/* Right Column - Conditionally Rendered */}
      {showForm && (
        <Grid item xs={12} sm={4}>
          <Paper style={{ padding: 16 }}>
            <GenerateDescription/>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default AttributeDisplay;
