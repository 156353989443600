import { useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_DATE, CREATE_NEW_APPOINTMENT, GET_DATES } from "./gql/dates.gql";

export const useAgenda = props => {
  const { productId } = props;
  const [updateDateMutation, { data: updatedData, error: errorUpdating, loading: updatingDate }] =
    useMutation(UPDATE_DATE);

  const [createNewAppointment, { data: dateData, loading: creatingDate, error: errorDate }] =
    useMutation(CREATE_NEW_APPOINTMENT);

  const { data: datesData, loading:gettingDates, error, refetch } = useQuery(GET_DATES, {
    variables: {
      id: decodeBase64ToInt(productId),
    },
    skip: !productId,
  });

  const dates = useMemo(() => {
    if (datesData?.datesByProduct) {
      const formatedDated = datesData.datesByProduct.map((dateClass) => {
        return {
          dateId:dateClass.id,
          text: dateClass.name,
          description: dateClass.description,
          startDate: new Date(dateClass.startDate),
          endDate: new Date(dateClass.endDate),
          allDay: dateClass.allDay || false,
        };
      });
      return formatedDated;
    } else {
      return [];
    }
  }, [datesData]);

  const createAppointment = e => {
    const { allDay, description, endDate, startDate, text } = e.appointmentData;
    createNewAppointment({
      variables: {
        input: {
          name: text,
          startDate: startDate,
          endDate: endDate,
          description: description,
          isEnable: true,
          qty: 10,
          allDay: allDay,
          productId: decodeBase64ToInt(productId),
        },
      },
    }).then(() => {
      refetch({
        variables: {
          id: decodeBase64ToInt(productId),
        },
      });
    });
  };

  const updateAppointment = values => {
    const { dateId, text, description, startDate, endDate, allDay } = values.newData;
    updateDateMutation({
      variables: {
        input: {
          dateId,
          name: text,
          startDate: startDate,
          endDate: endDate,
          description: description,
          isEnable: true,
          qty: 10,
          allDay: allDay,
          productId: decodeBase64ToInt(productId),
        }
      },
    }).then(() => {
      refetch({
        variables: {
          id: decodeBase64ToInt(productId),
        },
      });
    });
  };
  const deleteAppointment = values => {
    const { dateId, text, description, startDate, endDate, allDay } = values.appointmentData;
    updateDateMutation({
      variables: {
        input: {
          dateId,
          name: text,
          startDate: startDate,
          endDate: endDate,
          description: description,
          isEnable: true,
          qty: 10,
          allDay: allDay,
          productId: decodeBase64ToInt(productId),
          delete:true
        }
      },
    }).then(() => {
      refetch({
        variables: {
          id: decodeBase64ToInt(productId),
        },
      });
    });
  };

  return {
    createAppointment,
    updateAppointment,
    deleteAppointment,
    isLoading: updatingDate || creatingDate || gettingDates,
    dates
  };
};


const decodeBase64ToInt = (encodedStr) => {
  const decodedStr = atob(encodedStr);
  const numericPart = decodedStr.split(":")[1];

  return parseInt(numericPart, 10);
};