import { PRODUCT_TYPE_QUERY } from "@dashboard/talons/Products/product.gql";
import { useQuery } from "@apollo/client";
import { VALUES_PAGINATE_BY } from "@dashboard/config";
import { useMemo } from "react";

export const useProductType = (props) => {
  const { id } = props;
  const { data, loading, error } = useQuery(PRODUCT_TYPE_QUERY, {
        variables: {
          id: id,
          firstValues: VALUES_PAGINATE_BY,
        },
        skip: !id,
      },
    )
  const selectedProductType = useMemo(()=>{//ONLY RETURN ATTRIBUTES INCLUDED IN FORM
    if (data?.productType?.productAttributes) {
      const product=data?.productType
      const filteredProductData = {
        ...product,
        productAttributes: product.productAttributes.filter(
          (attribute) => attribute.visibleInProductForm
        )
      };
      return {
        productType:filteredProductData
      }
    }
    return null
  },[data])

  return {
    selectedProductType,
    loading,
  };
};