import { GENERATE_DESCRIPTION } from "@dashboard/@wl/components/OpenAI/gql/openAI.gql";
import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";


export const useGenerateDescription = () => {
  const [formData, setFormData] = useState({
    request: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [generateSubmit, { data, loading, error }] = useMutation(GENERATE_DESCRIPTION);


  const submit = useCallback((e) => {
    e.preventDefault();
    const { request } = formData;
    generateSubmit({
      variables: {
        request,
      },
    });
  }, [formData]);

  const description = useMemo(() => {
    if (data?.generateDescription) {
      return data.generateDescription.output.response;
    }
  }, [data]);

  return {
    description,
    submit,
    handleInputChange,
    loading
  };
};