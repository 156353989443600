import "devextreme/dist/css/dx.light.css";
import { Box, Divider, Typography } from "@material-ui/core";
import Scheduler, { Resource } from "devextreme-react/scheduler";
import React from "react";
import { useAgenda } from "@dashboard/talons/Attributes/useAgenda";
import "devextreme/dist/css/dx.dark.css";


const views = [
  "agenda",
  "day",
  "month",
  // "timelineDay",
  // "timelineMonth",
  // "timelineWeek",
  // "timelineWorkWeek",
  "week",
  "workWeek",
];
const App: React.FC = props => {
  const { name, productId } = props;
  const { createAppointment, dates, updateAppointment, deleteAppointment } = useAgenda({ productId });
  return (
    <Box key={"calendar"} my={2}>
      <Typography variant="h1">{name}</Typography>
      <Divider component="li" />
      <Scheduler
        timeZone="America/Mexico_City"
        dataSource={dates}
        views={views}
        defaultCurrentDate={new Date(2024, 8, 20)}
        startDayHour={9}
        height={600}
        onAppointmentAdding={createAppointment}
        onAppointmentUpdating={updateAppointment}
        onAppointmentDeleting={deleteAppointment}
      />
    </Box>
  );
};

export default App;
