import { gql, useQuery } from "@apollo/client";

export const useAttribute = (props: any) => {
  const { id, valuesPaginationState, settings } = props;

  const { data, loading } = useQuery(GET_ATTRIBUTE_QUERY, {
    variables: {
      id,
      firstValues: valuesPaginationState.first,
      lastValues: valuesPaginationState.last,
      afterValues: valuesPaginationState.after,
      beforeValues: valuesPaginationState.before,
    },
    skip: !settings,
  });

  return {
    data,
    loading,
  };
};

const GET_ATTRIBUTE_QUERY = gql`
  query AttributeDetails(
    $id: ID!
    $firstValues: Int
    $afterValues: String
    $lastValues: Int
    $beforeValues: String
  ) {
    attribute(id: $id) {
      ...AttributeDetails
      choices(first: $firstValues, after: $afterValues, last: $lastValues, before: $beforeValues) {
        ...AttributeValueList
        __typename
      }
      __typename
    }
  }

  fragment AttributeDetails on Attribute {
    ...Attribute
    ...Metadata
    availableInGrid
    inputType
    entityType
    unit
    storefrontSearchPosition
    valueRequired
    __typename
  }

  fragment Attribute on Attribute {
    id
    name
    slug
    type
    visibleInStorefront
    visibleInProductForm
    filterableInDashboard
    filterableInStorefront
    unit
    inputType
    __typename
  }

  fragment Metadata on ObjectWithMetadata {
    metadata {
      ...MetadataItem
      __typename
    }
    privateMetadata {
      ...MetadataItem
      __typename
    }
    __typename
  }

  fragment MetadataItem on MetadataItem {
    key
    value
    __typename
  }

  fragment AttributeValueList on AttributeValueCountableConnection {
    pageInfo {
      ...PageInfo
      __typename
    }
    edges {
      cursor
      node {
        ...AttributeValueDetails
        __typename
      }
      __typename
    }
    __typename
  }

  fragment PageInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
    __typename
  }

  fragment AttributeValueDetails on AttributeValue {
    ...AttributeValue
    plainText
    richText
    __typename
  }

  fragment AttributeValue on AttributeValue {
    id
    name
    slug
    file {
      ...File
      __typename
    }
    reference
    boolean
    date
    dateTime
    value
    __typename
  }

  fragment File on File {
    url
    contentType
    __typename
  }
`;
